import Dashboard from '../components/Dashboard/Dashboard'

const Home = () => {
    return (
        <div>
            <Dashboard />
        </div>
    )
}

export default Home
