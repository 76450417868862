import Graph from '../components/Graph/Graph'

const Analytics = () => {
    return (
        <div>
            <Graph />
        </div>
    )
}

export default Analytics
