import Schedule from '../components/Schedule/Schedule'

const SchedulePage = () => {
    return (
        <div>
            <Schedule />
        </div>
    )
}

export default SchedulePage
