import classes from './Professional.module.css'

const Professional = () => {
    return (
        <div className={classes.Professional}>
            <h4>Professional Info</h4>
            <div className={classes.SingleProfession}>
                <p>Profession header</p>
                <p>Date 19-2-2011</p>
                <p>Simp</p>
            </div>
        </div>
    )
}

export default Professional
