import Settings from '../components/Settings/Settings'

const SettingsPage = () => {
    return (
        <div>
            <Settings />
        </div>
    )
}

export default SettingsPage
