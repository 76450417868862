import Register from '../components/Register/Register'

const RegisterPage = () => {
    return (
        <div>
            <Register />
        </div>
    )
}

export default RegisterPage
