import DocProfile from '../components/DocProfile/DocProfile'

const ProfilePage = () => {
    return (
        <div>
            <DocProfile />
        </div>
    )
}

export default ProfilePage
